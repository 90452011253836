import React from "react";
import Footer from "../components/Footer";
import { HiOutlineCheck, HiOutlineChevronRight } from "react-icons/hi";
import { useNavigate } from "react-router-dom";

const PricingTier: React.FC<{
    name: string;
    price: string;
    description: string;
    features: string[];
    highlight?: boolean;
    buttonText?: string;
    storage: string;
    processing: string[];
    comingSoon?: boolean;
}> = ({
    name,
    price,
    description,
    features,
    highlight,
    buttonText = "Get Started",
    storage,
    processing,
    comingSoon = false,
}) => (
    <div
        className={`p-8 rounded-xl border flex flex-col ${
            highlight
                ? "border-purple-200 shadow-xl shadow-purple-500/10 bg-white relative"
                : "border-gray-200 bg-white"
        }`}
    >
        {highlight && (
            <div className="absolute -top-4 left-1/2 -translate-x-1/2">
                <span className="bg-gradient-to-r from-purple-600 to-blue-600 text-white px-4 py-1 rounded-full text-sm font-medium">
                    Most Popular
                </span>
            </div>
        )}

        <div className="flex-grow">
            <h3 className="text-2xl font-bold text-gray-900">{name}</h3>
            <div className="mt-4 mb-6">
                <div className="text-4xl font-bold text-gray-900">{price}</div>
                <p className="text-gray-600 mt-1">{description}</p>
            </div>

            <div className="space-y-4 mb-8">
                <div className="p-4 bg-gray-50 rounded-lg">
                    <div className="font-medium text-gray-900 mb-2">Storage</div>
                    <div className="text-gray-600">{storage}</div>
                </div>

                <div className="p-4 bg-gray-50 rounded-lg">
                    <div className="font-medium text-gray-900 mb-2">Processing Credits</div>
                    {processing.map((item, index) => (
                        <div key={index} className="text-gray-600 flex items-start gap-2">
                            <HiOutlineCheck className="w-5 h-5 text-green-500 flex-shrink-0 mt-0.5" />
                            {item}
                        </div>
                    ))}
                </div>
            </div>

            <div className="space-y-3">
                {features.map((feature, index) => (
                    <div key={index} className="flex items-start gap-2">
                        <HiOutlineCheck className="w-5 h-5 text-green-500 flex-shrink-0 mt-0.5" />
                        <span className="text-gray-600">{feature}</span>
                    </div>
                ))}
            </div>
        </div>

        <button
            disabled={comingSoon}
            className={`w-full mt-8 px-6 py-3 rounded-xl font-medium transition-all ${
                comingSoon
                    ? "bg-gray-100 text-gray-400 cursor-not-allowed"
                    : highlight
                      ? "bg-primary text-white hover:opacity-90 shadow-lg shadow-purple-500/20"
                      : "bg-white border border-gray-200 text-gray-700 hover:border-purple-200"
            }`}
        >
            {comingSoon ? "Coming Soon" : buttonText}
            {name === "Enterprise" && <HiOutlineChevronRight className="inline ml-2" />}
        </button>
    </div>
);

const FAQ: React.FC = () => {
    const faqs = [
        {
            question: "How do processing credits work?",
            answer: "Processing credits are used when you upload and analyze videos. Pro and Team plans refresh monthly, while Free users have a one-time credit allocation. Different operations (visual, speech, text) consume different amounts of credits.",
        },
        {
            question: "What's included in QuickReview basics?",
            answer: "QuickReview basics includes essential video review features like basic commenting and single-collaborator sharing. Advanced features like frame-perfect annotations, multi-user collaboration, and version control are available in Pro and higher tiers.",
        },
        {
            question: "Can I upgrade or downgrade at any time?",
            answer: "Yes, you can change your plan at any time. When upgrading, you'll be charged the prorated amount for the remainder of your billing cycle. When downgrading, the new price will take effect at the start of your next billing cycle.",
        },
        {
            question: "What happens if I exceed my storage limit?",
            answer: "You'll receive notifications as you approach your storage limit. Once reached, you'll need to either upgrade your plan or remove existing content to upload new files. We never delete your content without your explicit permission.",
        },
        {
            question: "Do unused processing credits roll over?",
            answer: "Processing credits do not roll over to the next month for any plan. Each billing cycle starts fresh with your plan's allocated credits. Enterprise customers may negotiate custom terms.",
        },
    ];

    return (
        <div className="container mx-auto px-6 py-24 border-t border-gray-200">
            <div className="text-center mb-16">
                <h2 className="text-3xl font-bold text-gray-900 mb-4">
                    Frequently Asked Questions
                </h2>
                <p className="text-xl text-gray-600">
                    Everything you need to know about our pricing and products
                </p>
            </div>

            <div className="max-w-3xl mx-auto">
                <div className="space-y-8">
                    {faqs.map((faq, index) => (
                        <div
                            key={index}
                            className="bg-white rounded-xl border border-gray-200 p-6 hover:border-purple-200 transition-colors"
                        >
                            <h3 className="text-lg font-medium text-gray-900 mb-2">
                                {faq.question}
                            </h3>
                            <p className="text-gray-600">{faq.answer}</p>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

const Pricing = () => {
    const navigate = useNavigate();

    return (
        <div className="min-h-screen bg-white">
            <div className="container mx-auto px-6 py-24">
                <div className="text-center mb-16">
                    <h1 className="text-4xl font-bold text-gray-900 mb-4">
                        Simple, Transparent Pricing
                    </h1>
                    <p className="text-xl text-gray-600">Choose the plan that's right for you</p>
                </div>

                <div className="grid md:grid-cols-3 gap-8 mb-16">
                    <PricingTier
                        name="Free"
                        price="$0"
                        description="Perfect for getting started"
                        storage="15GB"
                        processing={[
                            "200 mins total processing",
                            "Use for any modality (visual/audio/text)",
                            "One-time credit allocation",
                        ]}
                        features={[
                            "Basic search capabilities",
                            "30-day retention",
                        ]}
                    />

                    <PricingTier
                        name="Pro"
                        price="$12"
                        description="per month"
                        storage="200GB"
                        processing={[
                            "1,000 mins total processing",
                            "Use for any modality",
                            "Credits refresh monthly",
                        ]}
                        features={[
                            "Everything in Free +",
                            "Full search capabilities",
                            "Complete QuickReview",
                            "Unlimited sharing",
                            "Unlimited retention",
                            "Priority support",
                        ]}
                        highlight={true}
                    />

                    <PricingTier
                        name="Team"
                        price="$39"
                        description="per month"
                        storage="1TB"
                        processing={[
                            "5,000 mins total processing",
                            "Use for any modality",
                            "Credits refresh monthly",
                        ]}
                        features={[
                            "Everything in Pro +",
                            "5 team members ($8/additional user)",
                            "Team workspace",
                            "Advanced permissions",
                            "Usage analytics",
                            "Centralized billing",
                            "Priority support",
                        ]}
                    />
                </div>

                <div className="grid md:grid-cols-2 gap-8">
                    <PricingTier
                        name="Enterprise"
                        price="Custom"
                        description="Contact sales for pricing"
                        storage="Custom storage"
                        processing={["Custom processing credits"]}
                        features={[
                            "Unlimited team members",
                            "Multiple workspaces",
                            "Custom integrations",
                            "Enterprise security",
                            "SLA guarantees",
                            "Dedicated support",
                        ]}
                        buttonText="Contact Sales"
                    />

                    <PricingTier
                        name="Developer"
                        price="Pay as you go"
                        description="Usage-based pricing"
                        storage="15GB free, then $0.015/GB/month"
                        processing={["Visual: $0.02/min", "Speech: $0.005/min", "Text: $0.04/min"]}
                        features={[
                            "Full API access",
                            "No minimum commitment",
                            "Technical documentation",
                            "Community support",
                            "Volume discounts available",
                        ]}
                        buttonText="View Documentation"
                        comingSoon={true}
                    />
                </div>
            </div>

            <FAQ />
            <Footer />
        </div>
    );
};

export default Pricing;
